import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CustomButton } from "../..";
import { InputField } from "../components";
import "./investor-info-form.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import useInvestorFormContext from "../../../hooks/useInvestorFormContext";

export type InvestorInfoFormValuesType = {
  propertyAddress: string;
  fullName: string;
  investorName: string;
  companyName: string;
  investmentCapital: string;
  anthoneyName: string;
  areaOfInterest: string;
  referral: string;
};

const investorInfoSchema = Yup.object().shape({
  propertyAddress: Yup.string().required(
    "Property address, zip code is required"
  ),
  fullName: Yup.string().required("Full name is required"),
  investorName: Yup.string().required("Investor name is required"),
  companyName: Yup.string().required("Company name is required"),
  investmentCapital: Yup.string().required("Investment capital is required"),
  anthoneyName: Yup.string().required("Anthoney name is required"),
  areaOfInterest: Yup.string().required("Area of interest is required"),
  referral: Yup.string().required("Referral is required"),
});

const InvestorInfoForm = () => {
  const { investorFormState, setInvestorFormState } = useInvestorFormContext();
  const investorStateValues = investorFormState.investorInfo;

  const handleInvestorInfo = (values: InvestorInfoFormValuesType) => {
    setInvestorFormState({
      ...investorFormState,
      step: 2,
      investorInfo: { ...values },
    });
  };

  return (
    <div className="investor-info-form">
      <Formik<InvestorInfoFormValuesType>
        initialValues={{
          propertyAddress: investorStateValues
            ? investorStateValues.propertyAddress
            : "",
          fullName: investorStateValues ? investorStateValues.fullName : "",
          investorName: investorStateValues
            ? investorStateValues.investorName
            : "",
          companyName: investorStateValues
            ? investorStateValues.companyName
            : "",
          investmentCapital: investorStateValues
            ? investorStateValues.investmentCapital
            : "",
          anthoneyName: investorStateValues
            ? investorStateValues.anthoneyName
            : "",
          areaOfInterest: investorStateValues
            ? investorStateValues.areaOfInterest
            : "",
          referral: investorStateValues ? investorStateValues.referral : "",
        }}
        onSubmit={(values) => {
          handleInvestorInfo(values);
        }}
        validationSchema={investorInfoSchema}
      >
        {({ handleSubmit, values, handleChange, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <h3>Primary info</h3>
            <InputField
              name="propertyAddress"
              placeholder="Enter your property address, zip code"
            />
            <InputField name="fullName" placeholder="Full name" />
            <InputField name="investorName" placeholder="Investor name" />
            <InputField name="companyName" placeholder="Company name" />
            <InputField
              name="investmentCapital"
              placeholder="Investment capital"
            />
            <InputField name="anthoneyName" placeholder="Anthoney name" />
            <div className="flex">
              <InputField
                name="areaOfInterest"
                placeholder="Area of interest"
              />
              <InputField name="referral" placeholder="Referral" />
            </div>
            <div className="buttons">
              <CustomButton
                loadingText="Saving Information"
                type="submit"
                text="Next"
                skin="quaternary"
                icon={<FontAwesomeIcon icon={faArrowRight} />}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default InvestorInfoForm;
