import React, { useEffect, useState } from "react";
import { CustomButton } from "../..";
import "./investor-docs-form.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import useInvestorFormContext from "../../../hooks/useInvestorFormContext";
import axios from "axios";
import Modal from "antd/es/modal/Modal";
import { Alert } from "antd";

export type InvestorDocsFormValuesType = {
  docs: string;
};

const InvestorDocsForm = () => {
  const { investorFormState, setInvestorFormState } = useInvestorFormContext();
  const [fundsFiles, setFundsFiles] = useState<FileList | null>();
  const [fundsFilesError, setFundsFilesError] = useState<string | null>();
  const [fundFilesPreview, setFundFilesPreview] = useState<string[]>([]);
  const [companyDocs, setCompanyDocs] = useState<FileList | null>();
  const [companyDocsError, setCompanyDocsError] = useState<string | null>();
  const [companyDocsPreview, setCompanyDocsPreview] = useState<string[]>([]);
  const [formLoader, setFormLoader] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  // rendering fund files previews
  useEffect(() => {
    if (!fundsFiles) return;
    let tmp = [];
    for (let i = 0; i < fundsFiles.length; i++) {
      tmp.push(fundsFiles[i].name);
    }
    const objectUrls = tmp;
    setFundFilesPreview(objectUrls);

    // free memory
    for (let i = 0; i < objectUrls.length; i++) {
      return () => {
        URL.revokeObjectURL(objectUrls[i]);
      };
    }
  }, [fundsFiles]);

  // rendering company docs previews
  useEffect(() => {
    if (!companyDocs) return;
    let tmp = [];
    for (let i = 0; i < companyDocs.length; i++) {
      tmp.push(companyDocs[i].name);
    }
    const objectUrls = tmp;
    setCompanyDocsPreview(objectUrls);

    // free memory
    for (let i = 0; i < objectUrls.length; i++) {
      return () => {
        URL.revokeObjectURL(objectUrls[i]);
      };
    }
  }, [companyDocs]);

  const handleInvestorDocs = () => {
    if (!fundsFiles || !companyDocs) {
      if (!fundsFiles) {
        setFundsFilesError("please add a proof of funds file");
      }
      if (!companyDocs) {
        setCompanyDocsError("please add the company document");
      }
      setFormLoader(false);
    } else {
      try {
        const formData = new FormData();

        // add investor info data
        if (investorFormState.investorInfo) {
          formData.append(
            "property address",
            investorFormState.investorInfo.propertyAddress
          );
          formData.append("full name", investorFormState.investorInfo.fullName);
          formData.append(
            "investor name",
            investorFormState.investorInfo.investorName
          );
          formData.append(
            "company name",
            investorFormState.investorInfo.companyName
          );
          formData.append(
            "investment capital",
            investorFormState.investorInfo.investmentCapital
          );
          formData.append(
            "anthoney name",
            investorFormState.investorInfo.anthoneyName
          );
          formData.append(
            "area of interest",
            investorFormState.investorInfo.areaOfInterest
          );
          formData.append("referral", investorFormState.investorInfo.referral);
        }

        // add invstor asset type
        if (investorFormState.investorAssetType) {
          formData.append(
            "asset type",
            investorFormState.investorAssetType.assetType
          );
        }

        // add invstor ROI
        if (investorFormState.investorRoi) {
          formData.append("roi", investorFormState.investorRoi.roi);
        }

        // add invstor track record
        if (investorFormState.investorTrackRecord) {
          formData.append(
            "property 1",
            investorFormState.investorTrackRecord.property1
          );
          formData.append(
            "type 1",
            investorFormState.investorTrackRecord.type1
          );
          formData.append(
            "property 2",
            investorFormState.investorTrackRecord.property2
          );
          formData.append(
            "type 2",
            investorFormState.investorTrackRecord.type2
          );
          formData.append(
            "property 3",
            investorFormState.investorTrackRecord.property3
          );
          formData.append(
            "type 3",
            investorFormState.investorTrackRecord.type3
          );
        }

        //  add investor docs
        formData.append("fundFiles", fundsFiles[0]);
        formData.append("companyDocs", companyDocs[0]);

        console.log(
          "🚀 ~ file: InvestorDocsForm.tsx:74 ~ handleInvestorDocs ~ formData:",
          formData
        );

        // Make a POST request to Formspree with the FormData
        axios
          .post(
            "https://hook.us1.make.com/ki1o2hywi0v5br8evsya702eymq3yth8",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((_res) => {
            showSuccessModal();
            setFormLoader(false);
          })
          .catch((error) => {
            console.log(
              "🚀 ~ file: InvestorDocsForm.tsx:160 ~ handleInvestorDocs ~ error:",
              error
            );
            showErrorModal();
            setFormLoader(false);
          });
      } catch (error) {
        showErrorModal();
        console.error("Form submission error:", error);
      }
    }
  };

  const handleBack = () => {
    setInvestorFormState({ ...investorFormState, step: 4 });
  };

  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessOk = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  const showErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorOk = () => {
    setIsErrorModalOpen(false);
  };

  const handleErrorCancel = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <div className="investor-docs-form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setFormLoader(true);
          setFundsFilesError(null);
          setCompanyDocsError(null);
          handleInvestorDocs();
        }}
      >
        <div className="top">
          <h3>Proof of funds</h3>
          <p>(One year)</p>
        </div>
        <input
          type="file"
          name="fundFiles"
          id="fundFiles"
          onChange={(e) => {
            setFundsFilesError(null);
            if (e.target.files && e.target.files.length > 0) {
              setFundsFiles(e.target.files);
            }
          }}
        />
        <label htmlFor="fundFiles">Upload proof of funds files</label>
        {fundsFilesError && <p className="error">{fundsFilesError}</p>}
        <div className="docs">
          {fundFilesPreview &&
            fundFilesPreview.map((name, index) => (
              <div className="sub" key={index}>
                <p>{name}</p>
                <span
                  onClick={() => {
                    setFundsFiles(null);
                    setFundFilesPreview([]);
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
              </div>
            ))}
        </div>
        <div className="top">
          <h3>Company documents</h3>
        </div>
        <input
          type="file"
          name="companyDocs"
          id="companyDocs"
          onChange={(e) => {
            setCompanyDocsError(null);
            if (e.target.files && e.target.files.length > 0) {
              setCompanyDocs(e.target.files);
            }
          }}
        />
        <label htmlFor="companyDocs">Upload company documents </label>
        {companyDocsError && <p className="error">{companyDocsError}</p>}
        <div className="docs">
          {companyDocsPreview &&
            companyDocsPreview.map((name, index) => (
              <div className="sub" key={index}>
                <p>{name}</p>
                <span
                  onClick={() => {
                    setCompanyDocs(null);
                    setCompanyDocsPreview([]);
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
              </div>
            ))}
        </div>
        <div className="buttons">
          <CustomButton
            type="button"
            text="Back"
            skin="light"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            iconPositon="left"
            onClick={handleBack}
          />
          <CustomButton
            loadingText="Submitting"
            type="submit"
            text="Submit"
            skin="quaternary"
            disabled={formLoader}
            loading={formLoader}
          />
        </div>
      </form>
      <Modal
        title="Successfully submitted"
        open={isSuccessModalOpen}
        onOk={handleSuccessOk}
        onCancel={handleSuccessCancel}
      >
        <Alert
          // message="Submission complete "
          description="We have recieved your information and will get back to you in due time"
          type="success"
          showIcon
        />
      </Modal>
      <Modal
        title="Error submitting"
        open={isErrorModalOpen}
        onOk={handleErrorOk}
        onCancel={handleErrorCancel}
      >
        <Alert
          // message="Submission complete "
          description="We are having issues with supmitting our info. This is an error from our side please try again later"
          type="error"
          showIcon
        />
      </Modal>
    </div>
  );
};

export default InvestorDocsForm;
